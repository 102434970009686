<template>
    <div class="subcategory" :class="[{ document: type === 'column' }, { list: type === 'list' }, { selected: selected }, $mq]" @click="select()">
        <div class="name">{{ item.name }}</div>
    </div>
</template>

<script>
export default {
    name: 'LibraryFolder',
    props: {
        item: { type: Object, default: false },
        type: {
            type: String,
            default: 'column'
        }
    },
    data() {
        return {
            selected: true
        }
    },
    methods: {
        select() {
            this.$emit('openFolder')
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.subcategory {
    width: 250px;
    @include interaction();
    @include display-flex();
    @include flex-direction(column);
    @include align-items(flex-start);
    @include justify-content();
    @include border-radius(4px);
    @include bgHover(#fff);
    @include background($image: img('folder.svg'), $size: 40px, $position: left 6px center);
    @include flex-wrap(nowrap);
    float: left;
    width: 100%;
    height: 68px;
    overflow: hidden;
    background-color: #fff;
    padding: 8px;
    padding-left: 45px;
    cursor: pointer;

    .name {
        // line-height: 19px;
        line-height: 1.1;
        font-family: $text-bold;
        font-weight: 600;
        font-size: 16px;
        @include text-ellipsis($line: 3);
        @include font-size(md);
        hyphens: auto;
        word-wrap: break-word;
    }

    &.portrait {
        .name {
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            word-wrap: break-word;
        }
    }
}

.list {
    .subcategory {
        width: 100%;
        height: 56px;

        .name {
            line-height: 1.3;
        }
    }
}
</style>
